import { render, staticRenderFns } from "./ChatEmojisDropdown.vue?vue&type=template&id=317b49ab&scoped=true"
import script from "./ChatEmojisDropdown.vue?vue&type=script&lang=js"
export * from "./ChatEmojisDropdown.vue?vue&type=script&lang=js"
import style0 from "./ChatEmojisDropdown.vue?vue&type=style&index=0&id=317b49ab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "317b49ab",
  null
  
)

export default component.exports