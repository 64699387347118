<template>
  <div class="banned-page">
    <div class="logo-container">
      <img src="@/assets/img/logo.png" />
    </div>
    <div class="content-container">
      <h3>You have been banned, Your account has been banned from buxrush</h3>
      <h5 class="italic-text">If you think this is an accident, please contact us on <a href="https://discord.gg/buxrush" target="_blank" class="discord-link">Discord</a>.</h5>
      <br>

      <button v-on:click="profileCopyButton(authUser.user.ban.reason)" class="white-text">Ban reason: {{ authUser.user.ban.reason.substring() }}</button>
<br>
      <button v-on:click="BannedLogoutButton()" class="logout-button">Logout</button>

      <br>
      <div class="additional-info">
        <div class="Reason">
          <button v-on:click="toggleSensitiveInfo" class="white-text">Click to see sensitive info!</button>
          <div v-if="showSensitiveInfo">
            <button v-on:click="profileCopyButton(authUser.user.ban.expire)" class="white-text">Ban expire: {{ authUser.user.ban.expire.substring(0, 10) }}</button>
            <br>
            <button v-on:click="profileCopyButton(authUser.user._id)" class="white-text">Bbuxrushandit id: {{ authUser.user._id.substring() }}</button>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppBanned',
  data() {
    return {
      showSensitiveInfo: false
    };
  },
  methods: {
    ...mapActions([
      'modalsSetShow',
      'authLogoutUser'
    ]),
    profileCopyButton(value) {
      const el = document.createElement('textarea');
      el.value = value;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    },
    BannedLogoutButton() {
      this.authLogoutUser();
    },
    toggleSensitiveInfo() {
      this.showSensitiveInfo = !this.showSensitiveInfo;
    }
  },
  computed: {
    ...mapGetters([
      'authUser'
    ]),
  }
};
</script>


<style>
html, body {
  margin: 0;
  padding: 0;
}
.banned-page {
  background-color: #080808;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  height: 100vh; 
  color: #ffffff; 
  font-weight: bold;
  font-family: Arial, sans-serif;
}
.white-text {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 14px;
  position: center;
  color: rgb(201, 201, 201);
  cursor: pointer; }

.bandit-id {
  position: center;
  color: rgb(126, 126, 126);
  cursor: pointer; 
}
.logout-button {
  background-color: #272727; 
  color: white; 
  padding: 8px 16px; 
  border: none; 
  border-radius: 7px; 
  cursor: pointer; 
  margin-top: 20px;
  align-self: center; 
}
.content-container .additional-info .Reason{
  color: #ffffff;
}

.logo-container {
  margin-bottom: 20px; 
}
.discord-link {
  color: rgb(199, 11, 11); 
  font-weight: bold; 
  text-decoration: none; 
}
.content-container {
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}

.italic-text {
  font-style: italic;
}

.additional-info {
  color: grey;
  margin-top: 20px; 
}
.additional-info .Reason {
  color: #ffffff;
}
.additional-info h4 {
  color: white;
  font-size: large;
  font-weight: bold;
}

.additional-info p {
  color: grey;
  margin: 5px 0; 
}
</style>
