<template>
    <div class="home">
        <div class="home-banner">

            <HomeBannerUser />
            <HomeBannerNews />

        </div>
        <div class="home-featured">
            <div class="featured-title">Popular Games</div>
            <div class="featured-content">

                <HomeFeaturedLink game="battles" />
                <HomeFeaturedLink game="crash" />
                <HomeFeaturedLink game="towers" />
                <HomeFeaturedLink game="duels" />
                <HomeFeaturedLink game="mines" />
                <HomeFeaturedLink game="unbox" />

            </div>
        </div>

        <Bets />
    </div>
</template>

<script>
    import Bets from '@/components/bets/Bets';
    import HomeBannerUser from '@/components/home/HomeBannerUser';
    import HomeBannerNews from '@/components/home/HomeBannerNews'
    import HomeFeaturedLink from '@/components/home/HomeFeaturedLink';
    import HomeGameElement from '@/components/home/HomeGameElement';
    import HomePlaceholderElement from '@/components/home/HomePlaceholderElement';

    export default {
        name: 'Home',
        metaInfo: {
            title: 'BuxRush: The #1 Roblox Arcade Site'
        },
        components: {
            Bets,
            HomeBannerUser,
            HomeBannerNews,
            HomeFeaturedLink,
            HomeGameElement,
            HomePlaceholderElement,
        }
    }
</script>

<style scoped>
    .home {
        background-color: #111c29;   
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 45px 10px;
    }

    .home .home-banner {
        width: 1250px;
        display: flex;
        flex-wrap: wrap;
    }

    .home .home-featured,
    .home .home-games {
        width: 1250px;
        position: relative;
        padding: 70px 0;
        margin-bottom: 80px;
    }

    .home .home-featured {
        margin-top: 55px;
    }

    .home .home-games {
        padding: 65px 0 40px 0;
    }

    .home .home-featured::before,
    .home .home-games::before {
        
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .home .home-games::before {
    }

    .home .home-games::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .home .featured-title,
    .home .games-title {
        width: 300px;
        height: 43px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 7.5%;
        transform: translate(-50%, -50%);
        font-family: 'Rubik';
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
    }

    .home .featured-title::before,
    .home .games-title::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
        z-index: -1;
    }

    .home .games-title::before {
        background: linear-gradient(255deg, rgb(0, 0, 0) -25%, rgb(0, 0, 0) 100%);
    }

    .home .featured-content,
    .home .games-content {
        margin-bottom: -200px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    @media only screen and (max-width: 1600px) {

        .home .home-banner,
        .home .home-featured,
        .home .home-games {
            width: 100%;
        }

    }

    @media only screen and (max-width: 950px) {

        .home {
            padding: 25px 10px 100px;
        }

        .home .featured-title,
    .home .games-title {
        width: 300px;
        height: 43px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 'Rubik';
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
    }

    }
</style>
