import { render, staticRenderFns } from "./HomeNewsElement.vue?vue&type=template&id=2803623a&scoped=true"
import script from "./HomeNewsElement.vue?vue&type=script&lang=js"
export * from "./HomeNewsElement.vue?vue&type=script&lang=js"
import style0 from "./HomeNewsElement.vue?vue&type=style&index=0&id=2803623a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2803623a",
  null
  
)

export default component.exports