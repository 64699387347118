<template>
    <div class="modal-battles-modes" v-bind:class="[ 'modes-' + modalsData.mode ]">
        <div class="modes-header">
            <span>{{ modalsData.mode.toUpperCase() }} MODE</span>
        </div>
        <div class="modes-info">
            {{ 
                modalsData.mode === 'cursed' ? 
                    'Cursed Mode is a unique mode that changes the nature of Case Battles. Within this mode, the team or user which pulls the least is the one who wins the battle.' :
                    'Terminal Mode is a unique mode that changes the nature of Case Battles. Within this mode, the last case in the case battle is the one that determines the outcome of the battle.'
            }}
        </div>
        <button v-on:click="modalsSetShow(null)" class="button-proceed">
            <div class="button-inner">PROCEED</div>
        </button>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'ModalBattlesModes',
        methods: {
            ...mapActions([
                'modalsSetShow'
            ])
        },
        computed: {
            ...mapGetters([
                'modalsData'
            ])
        }
    }
</script>

<style scoped>
    .modal-battles-modes {
        width: 600px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 35px 30px 30px 30px;
        border-radius: 15px;
        background: #1f2330;
        border: 1px solid rgb(29, 29, 29);
    }

    .modal-battles-modes .modes-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-battles-modes .header-icon {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .modal-battles-modes .header-icon img {
        width: 48px;
        height: 48px;
    }

    .modal-battles-modes .modes-header span {
        margin-top: 20px;
        text-align: center;
        font-size: 32px;
        font-weight: 900;
    }

    .modal-battles-modes.modes-cursed .modes-header span {
        color: #ea3f31;
    }

    .modal-battles-modes.modes-terminal .modes-header span {
        color: #a50000;
    }

    .modal-battles-modes .modes-info  {
        margin-top: 12px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .modal-battles-modes button.button-proceed {
        width: 230px;
        height: 48px;
        margin-top: 35px;
    }

    .modal-battles-modes button.button-proceed .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
        background: linear-gradient(255deg, #161616 0%, #161616 75%);
        border-radius: 10px;
    }

    @media only screen and (max-width: 620px) {

        .modal-battles-modes {
            width: calc(100vw - 20px);
            padding: 35px 10px 30px 10px;
        }

    }

    @media only screen and (max-width: 500px) {

        .modal-battles-modes {
            padding: 65px 10px 30px 10px;
        }

    }
</style>