<template>
    <div class="sidebar">
      <div class="toggle-button-container">
      </div>
      <div v-if="showSidebar" class="nav-links">
        <router-link to="/crash" class="nav-link" :class="{ 'active': isActive('/crash') }">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="m6.226 13.63-.36.36c-.747-.699-1.672-1.112-3.35-.78a.602.602 0 0 1-.533-1.02l3.172-3.173a.603.603 0 0 1 .207-.135c3.038-1.192 4.495-.802 5.477.064 6.558-6.683 7.74-7.57 13.774-6.147 1.423 6.038.537 7.215-6.161 13.788.866.981 1.258 2.438.066 5.48a.603.603 0 0 1-.136.206l-3.173 3.172a.603.603 0 0 1-1.019-.533c.331-1.678-.081-2.603-.78-3.35l1.134-1.135c-.36.358-.732.727-1.114 1.11l-.026.026-1.334-1.334-2.334.418a.603.603 0 0 1-.533-.167L6.93 18.207a.603.603 0 0 1-.167-.534l.419-2.332-1.334-1.333.378-.378Zm14.652-7.096a2.765 2.765 0 0 0-3.908 0 2.765 2.765 0 1 0 3.908 0ZM3.493 23.709s.718-2.685 1.448-3.415a1.392 1.392 0 0 1 1.967 1.968c-.73.73-3.415 1.447-3.415 1.447Z" fill="rgb(126, 136, 149)"></path>          </svg>
          <span>Crash</span>
        </router-link>
        <router-link to="/unbox" class="nav-link" :class="{ 'active': isActive('/unbox') }">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.89.762v7.52H1.607v-3.85a3.672 3.672 0 0 1 3.67-3.67h.613Zm13.368 0v7.52h-2.023v-.541a2.267 2.267 0 0 0-2.266-2.267h-2.937a2.268 2.268 0 0 0-2.267 2.267v.542H7.743V.763h11.515Zm6.136 7.52H21.11V.763h.614a3.672 3.672 0 0 1 3.67 3.67v3.85ZM21.11 20.078v-9.942h4.284v6.272a3.672 3.672 0 0 1-3.67 3.67h-.614Zm-13.367 0v-9.942h2.022v.542a2.268 2.268 0 0 0 2.267 2.267h2.937a2.268 2.268 0 0 0 2.266-2.267v-.542h2.023v9.942H7.743Zm-6.136-9.942H5.89v9.942h-.613a3.672 3.672 0 0 1-3.67-3.67v-6.272Zm13.776.542a.414.414 0 0 1-.414.415h-2.937a.414.414 0 0 1-.414-.415V7.741c0-.229.185-.415.414-.415h2.937c.228 0 .414.186.414.415v2.936Z" fill="rgb(126, 136, 149)"></path>
          </svg>
          <span>Cases</span>
        </router-link>
        <router-link to="/Battles" class="nav-link" :class="{ 'active': isActive('/Battles') }">
            <svg data-v-7d622f5c="" data-v-4fdcdf5f="" width="25" height="20" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-7d622f5c="" data-v-4fdcdf5f="" d="M3.85167 8.41955L6.81332 11.2805L5.62916 12.4246L6.815 13.5695L5.63 14.7136L3.55583 12.711L1.185 15L0 13.8559L2.37084 11.5662L0.296669 9.56443L1.48167 8.42036L2.66667 9.56362L3.85083 8.41955H3.85167ZM0.457574 0L3.42929 0.00242733L13.3325 9.56443L14.5183 8.42036L15.7033 9.56443L13.63 11.567L16 13.8559L14.815 15L12.4442 12.711L10.37 14.7136L9.185 13.5695L10.37 12.4246L0.460088 2.85695L0.457574 0ZM12.5732 0L15.5424 0.00242733L15.5441 2.8529L12.1475 6.1314L9.18416 3.27121L12.5732 0Z" fill="rgb(126, 136, 149)"></path></svg>
            <span>Case Battle</span>
        </router-link>
        <router-link to="/towers" class="nav-link" :class="{ 'active': isActive('/towers') }">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.668 4.02h3.027V1.125A1.124 1.124 0 0 1 8.819 0h2.42a1.124 1.124 0 0 1 1.124 1.124V4.02h2.899V1.124A1.123 1.123 0 0 1 16.385 0h1.86a1.686 1.686 0 0 1 1.685 1.685V5.65a1.449 1.449 0 0 1-1.449 1.449h-.471c-.627 0-1.135.508-1.135 1.134v10.63c0 .627.508 1.135 1.135 1.135h.235a1.686 1.686 0 0 1 1.685 1.685v.951a1.124 1.124 0 0 1-1.124 1.124H1.124A1.123 1.123 0 0 1 0 22.634v-.95a1.686 1.686 0 0 1 1.685-1.686h.389c.626 0 1.134-.508 1.134-1.135V8.233c0-.626-.508-1.134-1.134-1.134h-.625A1.449 1.449 0 0 1 0 5.649V1.686A1.685 1.685 0 0 1 1.685 0h1.86a1.124 1.124 0 0 1 1.123 1.124V4.02Zm7.167 15.433c.36 0 .651-.291.651-.651v-3.96a2.522 2.522 0 0 0-5.042 0v3.96c0 .36.291.651.651.651h3.74Z" fill="rgb(126, 136, 149)"></path>          </svg>
          <span>Towers</span>
        </router-link>
        <router-link to="/mines" class="nav-link" :class="{ 'active': isActive('/mines') }">
          <svg width="50" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="m21.943 9.066 1.197 1.197a.797.797 0 0 1 0 1.127l-.96.96a.798.798 0 0 1-.892.164 10.45 10.45 0 0 0-4.243-4.23.798.798 0 0 1 .152-.916l.96-.96a.797.797 0 0 1 1.127 0l1.596 1.595a.82.82 0 0 1 .02-.015s.255-.25.968-1.388c.383-.61.665-1.34.52-2.133-.097-.536-.39-1.09-.938-1.656a.762.762 0 0 1 1.095-1.06c.803.829 1.2 1.658 1.343 2.443.217 1.19-.154 2.3-.729 3.216-.575.917-.994 1.418-1.216 1.656Zm-9.867-.623c4.983 0 9.027 4.006 9.027 8.94 0 4.933-4.044 8.938-9.027 8.938-4.982 0-9.027-4.005-9.027-8.939 0-4.933 4.045-8.939 9.027-8.939Zm-2.701 4.248a.847.847 0 0 0-1.275-.203 6.103 6.103 0 0 0-2.114 4.616 6.08 6.08 0 0 0 2.667 5.031 1.04 1.04 0 0 0 1.55-.469c.013-.008.018-.019.022-.03a.683.683 0 0 0-.294-.85 4.246 4.246 0 0 1-2.127-3.682 4.26 4.26 0 0 1 1.387-3.147c.354-.324.428-.853.179-1.263a.043.043 0 0 0 .005-.004Z" fill="rgb(126, 136, 149)"></path>
          </svg>
          <span>Mines</span>
        </router-link>
        <router-link to="/duels" class="nav-link" :class="{ 'active': isActive('/duels') }">
            <svg data-v-4fdcdf5f="" width="25" height="20" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-4fdcdf5f="" d="M3.85167 8.41955L6.81332 11.2805L5.62916 12.4246L6.815 13.5695L5.63 14.7136L3.55583 12.711L1.185 15L0 13.8559L2.37084 11.5662L0.296669 9.56443L1.48167 8.42036L2.66667 9.56362L3.85083 8.41955H3.85167ZM0.457574 0L3.42929 0.00242733L13.3325 9.56443L14.5183 8.42036L15.7033 9.56443L13.63 11.567L16 13.8559L14.815 15L12.4442 12.711L10.37 14.7136L9.185 13.5695L10.37 12.4246L0.460088 2.85695L0.457574 0ZM12.5732 0L15.5424 0.00242733L15.5441 2.8529L12.1475 6.1314L9.18416 3.27121L12.5732 0Z" fill="rgb(126, 136, 149)"></path></svg>
            <span>Dice Duels</span>
        </router-link>
        
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Sidebar',
    data() {
      return {
        showSidebar: true,
        activeLink: null
      };
    },
    methods: {
      toggleSidebar() {
        this.showSidebar = !this.showSidebar;
      },
      isActive(route) {
        return this.activeLink === route;
      }
    },
    watch: {
      $route(to, from) {
        this.activeLink = to.path;
      }
    }
  };
  </script>
  
  <style scoped>
  .sidebar {
    position: fixed;
    left: 0;
    top: 80px;
    bottom: 0; 
    width: 110px; 
    z-index: 50;
    background: radial-gradient(50% 50% at 50% 50%, #1f2330 0%, #1f2330 100%);
    border-right: 1px solid #243042;
  }
  
  .toggle-button-container {
    margin-bottom: 1em;
  }
  
  .toggle-button {
    color: white;
  }
  
  .nav-links {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: #7e8895;
    font-size: 14px;
  }
  .nav-link.active {
    color: hsla(0, 0%, 100%, .8);
    font-weight: 500;
    border-left: 4px solid #fff;
  }

  .nav-link.active svg path {
    transition: .3s;
    fill: #2967e0;
  }

  .nav-link {
    margin: 0.5em;
    padding: 0.5em 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(197, 197, 197);
    font-size: 13px;
    width: 100px;
  }

  .nav-link svg {
    margin-bottom: 0.8em;
  }
  @media only screen and (max-width: 1175px) {
  .sidebar {
    display: none;
  }
}
  </style>
  
