<template>
    <div class="modal-login">
        <div class="login-sidebar">
            <img src="@/assets/img/loginlogo.png" class="sidebar-image" />
            <div class="sidebar-separator"></div>
        </div>
        <div class="login-content">
            <div class="content-title">
                <span class="gradient-white">{{ modalTab === 'forgot' ? 'RESET PASSWORD' : modalTab.toUpperCase() }}</span>
            </div>
            <div v-if="['login', 'register', 'roblox login', 'roblox cookie'].includes(modalTab) === true" class="content-auth">
                <LoginRoblox v-if="['roblox login', 'roblox cookie'].includes(modalTab) === true" v-bind:tab="modalTab" />
                <LoginCredentials v-else v-bind:tab="modalTab" />

                <div class="auth-seperator">OR SIGN IN USING</div>
                <button v-if="['roblox login', 'roblox cookie'].includes(modalTab) === true" v-on:click="modalSetTab('login')" class="button-credentials">
                    <div class="button-inner">Sign in with Email</div>
                </button>
                <button v-else v-on:click="modalSetTab('roblox login')" class="button-roblox">
                    <div class="button-inner">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0L0 16L15.5 20L20 4L4 0ZM7.5 11L8.5 8L12 9L11 12L7.5 11Z" />
                        </svg>
                        Sign in with Roblox
                    </div>
                </button>
            </div>
            <LoginForgot v-else />
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import LoginCredentials from '@/components/login/LoginCredentials';
    import LoginRoblox from '@/components/login/LoginRoblox';
    import LoginForgot from '@/components/login/LoginForgot';

    export default {
        name: 'ModalLogin',
        components: {
            LoginCredentials,
            LoginRoblox,
            LoginForgot
        },  
        data() {
            return {
                modalTab: 'login'
            }
        },
        methods: {
            ...mapActions([
                'modalsSetShow'
            ]),
            modalSetTab(tab) {
                this.modalTab = tab;
            },
            modalTermsButton() {
                this.modalsSetShow(null);
                setTimeout(() => { this.modalsSetShow('Terms'); }, 300);
            }
        }
    }
</script>

<style scoped>
.modal-login {
    display: flex; /* Enable flexbox */
    width: 925px;
    border-radius: 15px;
    border: 1px solid hsla(0, 0%, 100%, .08);
    box-shadow: 0 4px 50px rgba(0,0,0,.5);
    background: #243042;
}

.modal-login .login-sidebar {
    width: 50%; 
    height: 100%;
    display: flex;
    justify-content: center;
}

.modal-login .sidebar-image {
    width: 100%;
    height: 100%;
    object-fit: contain; 
    border-radius: 15px 0 0 15px;
    margin-left: 10px;
    max-width: 100%; /* Ensure the image does not exceed its container */
    max-height: 100%; /* Maintain aspect ratio within container */
}

.modal-login .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}
.modal-login .sidebar-separator {
    width: 1px; 
    height: 100%;
    background: #2e0101; 
    margin-left: 10px; 
}
.modal-login .content-title {
    text-align: center;
    font-size: 32px;
    font-weight: 900;
}

.modal-login .content-auth {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 35px;
    }
.modal-login .auth-seperator {
    width: 530px;
    position: relative;
    margin-top: 35px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #e0e0e0;
}

.modal-login .auth-seperator:before,
.modal-login .auth-seperator:after {
    content: '';
    width: calc(50% - 110px);
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background: #ffffff;
    opacity: 0.5;
}

.modal-login .auth-seperator:before {
    left: 0;
}

.modal-login .auth-seperator:after {
    right: 0;
}

.modal-login button.button-credentials,
.modal-login button.button-roblox {
    width: 100%; 
    height: 54px;
    margin-top: 35px;
}

.modal-login button.button-credentials .button-inner,
.modal-login button.button-roblox .button-inner {
    width: 100%; 
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    background: #18202c;
    border-radius: 10px;
}

.modal-login button.button-roblox .button-inner {
    background: #18202c;
}

.modal-login button.button-roblox .button-inner svg {
    margin-right: 14px;
    fill: #ffffff;
}

@media only screen and (max-width: 1030px) {
    .modal-login {
        width: calc(100vw - 20px);
    }
}

@media only screen and (max-width: 950px) {
    .modal-login .login-sidebar {
        display: none;
    }

    .modal-login .login-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 85px 15px 48px 15px;
    }
}

@media only screen and (max-width: 560px) {
    .modal-login .auth-seperator {
        width: 100%;
    }
}
</style>
