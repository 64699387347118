<template>
    <div class="admin-user-games">
        <div class="games-head">
            <div class="head-date">DATE</div>
            <div class="head-game">GAME</div>
            <div class="head-id">GAME ID</div>
            <div class="head-amount">AMOUNT</div>
        </div>
        <div class="games-content">
            <transition name="fade" mode="out-in">
                <div v-if="adminUserGameList.game === null || adminUserGameList.loading === true" class="content-loading" key="loading">
                    <LoadingAnimation />
                </div>
                <div v-else-if="adminUserGameList.data.length > 0" class="content-list" key="data">

                    <AdminUserGamesElement v-for="game in adminUserGameList.data" v-bind:key="game._id" v-bind:game="game" />

                </div>
                <div v-else class="content-empty" key="empty">No games found.</div>
            </transition>
        </div>
        <div class="games-pagination">
            <button v-on:click="adminSetPage(adminUserGameList.page - 1)" class="button-prev" v-bind:disabled="adminUserGameList.page <= 1">
                <div class="button-inner">
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.2788 4.30337C11.2297 4.2954 11.18 4.29173 11.1302 4.29237H2.66447L2.84907 4.20651C3.02951 4.12111 3.19366 4.00487 3.33417 3.86307L5.70819 1.48906C6.02085 1.19059 6.07339 0.710444 5.83269 0.351417C5.55254 -0.0311676 5.0153 -0.114237 4.63269 0.165907C4.60178 0.188552 4.5724 0.213237 4.54479 0.2398L0.251817 4.53278C-0.0836794 4.8679 -0.0839745 5.41152 0.251146 5.74702C0.251361 5.74723 0.251602 5.74747 0.251817 5.74769L4.54479 10.0407C4.88056 10.3755 5.42418 10.3747 5.75903 10.039C5.78538 10.0125 5.80999 9.98443 5.83269 9.95481C6.07339 9.59578 6.02085 9.11564 5.70819 8.81717L3.33847 6.43886C3.21249 6.31275 3.06766 6.20701 2.90917 6.12547L2.65159 6.00956H11.083C11.5216 6.02585 11.9064 5.71946 11.9888 5.28834C12.0647 4.82027 11.7468 4.3793 11.2788 4.30337Z" />
                    </svg>
                </div>
            </button>
            <div class="pagination-info">
                PAGE <span class="text-green-gradient">{{adminUserGameList.page}}</span> / {{Math.ceil(adminUserGameList.count / 14) <= 0 ? '1' : Math.ceil(adminUserGameList.count / 14)}}
            </div>
            <button v-on:click="adminSetPage(adminUserGameList.page + 1)" class="button-next" v-bind:disabled="adminUserGameList.page >= Math.ceil(adminUserGameList.count / 14)">
                <div class="button-inner">
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.721245 4.30337C0.770346 4.2954 0.820037 4.29173 0.869755 4.29237H9.33553L9.15093 4.20651C8.97049 4.12111 8.80634 4.00487 8.66583 3.86307L6.29181 1.48906C5.97915 1.19059 5.92661 0.710444 6.16731 0.351417C6.44746 -0.0311676 6.9847 -0.114237 7.36731 0.165907C7.39822 0.188552 7.4276 0.213237 7.45521 0.2398L11.7482 4.53278C12.0837 4.8679 12.084 5.41152 11.7489 5.74702C11.7486 5.74723 11.7484 5.74747 11.7482 5.74769L7.45521 10.0407C7.11944 10.3755 6.57582 10.3747 6.24097 10.039C6.21462 10.0125 6.19001 9.98443 6.16731 9.95481C5.92661 9.59578 5.97915 9.11564 6.29181 8.81717L8.66153 6.43886C8.78751 6.31275 8.93234 6.20701 9.09083 6.12547L9.34841 6.00956H0.917005C0.478396 6.02585 0.0935841 5.71946 0.0111866 5.28834C-0.0647192 4.82027 0.253177 4.3793 0.721245 4.30337Z" />
                    </svg>
                </div>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import AdminUserGamesElement from '@/components/admin/user/AdminUserGamesElement';

    export default {
        name: 'AdminUserGames',
        components: {
            LoadingAnimation,
            AdminUserGamesElement
        },
        methods: {
            ...mapActions([
                'adminSetUserGameListPage', 
                'adminGetUserGameListSocket'
            ]),
            adminSetPage(page) {
                if(this.adminUserGameList.page === page) { return; }
                if(page < 1 || page > Math.ceil(this.adminUserGameList.count / 14)) { return; }

                this.adminSetUserGameListPage(page);

                const data = { userId: this.modalsData.user._id, page: this.adminUserGameList.page };
                this.adminGetUserGameListSocket(data);
            }
        },
        computed: {
            ...mapGetters([
                'modalsShow', 
                'modalsData', 
                'adminUserGameList'
            ])
        },
        created() {
            if(this.adminUserGameList.loading === false) {
             const data = { userId: this.modalsData.user._id, page: this.adminUserGameList.page };
                this.adminGetUserGameListSocket(data);
            }
        }
    }
</script>

<style scoped>
    .admin-user-games {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .admin-user-games .games-head {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 30px 20px 20px;
        border-bottom: 1px solid rgba(31, 31, 31, 0.5);
    }

    .admin-user-games .head-date {
        width: 30%;
        font-size: 14px;
        font-weight: 700;
        color: #cccccc;
    }

    .admin-user-games .head-game {
        width: 14%;
        font-size: 14px;
        font-weight: 700;
        color: #cccccc;
    }

    .admin-user-games .head-id {
        width: 37%;
        font-size: 14px;
        font-weight: 700;
        color: #cccccc;
    }

    .admin-user-games .head-amount {
        width: 19%;
        text-align: right;
        font-size: 14px;
        font-weight: 700;
        color: #cccccc;
    }

    .admin-user-games .games-content {
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid rgba(34, 34, 34, 0.5);
    }

    .admin-user-games .content-loading {
        width: 100%;
        height: 330px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-user-games .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-user-games .content-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-user-games .content-list {
        width: 100%;
        height: 330px;
        padding-right: 10px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .admin-user-games .content-list::-webkit-scrollbar {
        width: 5px;
        height: 0;
    }

    .admin-user-games .content-list::-webkit-scrollbar-track {
        background-color: #000000;
    }

    .admin-user-games .content-list::-webkit-scrollbar-thumb {
        background-color: #1a1a1a;
    }

    .admin-user-games .content-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #d4d4d4;
    }

    .admin-user-games .content-list.fade-enter-active,
    .admin-user-games .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .admin-user-games .content-list.fade-enter-from,
    .admin-user-games .content-empty.fade-enter-from {
        opacity: 0;
    }

    .admin-user-games .games-pagination {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
    }

    .admin-user-games .games-pagination button {
        width: 52px;
        height: 37px;
        position: relative;
        padding: 1px;
        z-index: 1;
    }

    .admin-user-games .games-pagination button .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1b1b1b;
        border-radius: 10px;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);
    }

    .admin-user-games .games-pagination button:disabled .button-inner {
        background: #1b1b1b;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);
    }

    .admin-user-games .games-pagination button .button-inner svg {
        fill: #c7c7c7;
    }

    .admin-user-games .games-pagination button:disabled .button-inner svg {
        fill: #c7c7c7;
    }

    .admin-user-games .pagination-info {
        font-size: 12px;
        font-weight: 800;
        color: #c5c5c5;
    }

    @media only screen and (max-width: 725px) {

        .admin-user-games .games-head {
            padding: 0;
        }

        .admin-user-games .head-date,
        .admin-user-games .head-game,
        .admin-user-games .head-id,
        .admin-user-games .head-amount {
            display: none;
        }

    }
</style>
