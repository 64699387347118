<template>
    <div class="app-loader">
        <div class="loader-container">
            <div class="container-inner">
                <img src="@/assets/img/LoadingLogo.png" class="bouncing-logo" />
            </div>
        </div>
        <transition name="fade">
            <div v-if="showMessage" class="connecting-message">
                Trying to connect...
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    name: 'AppMaintenance',
    data() {
        return {
            showMessage: false
        };
    },
    mounted() {
        this.timeout = setTimeout(() => {
            this.showMessage = true;
        }, 4000);

        this.simulateLoading();
    },
    methods: {
        simulateLoading() {
            setTimeout(() => {
                clearTimeout(this.timeout);
            }, 6000); 
        }
    },
    beforeDestroy() {
        clearTimeout(this.timeout);
    }
}
</script>

<style scoped>
    .app-loader {
        background: radial-gradient(50% 50% at 50% 50%, #0d111c 0%, #0d111c 100%);
        width: 100%;
        height: 100%;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        flex-direction: column; 
    }

    .app-loader .loader-container {
        width: 200px;
        height: 200px; 
        position: relative;
        padding: 2px;
        border-radius: 10px; 
        overflow: hidden;
    }

    .app-loader .container-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center; 
        border-radius: 10px; 
        background: transparent;
        overflow: hidden;
    }

    .app-loader .container-inner img {
        width: 170px;
        height: auto; 
    }


    @keyframes bounce {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-20px);
        }
    }

    .bouncing-logo {
        animation: bounce 1.5s infinite;
    }


    .connecting-message {
        margin-top: 20px;
        font-size: 1.2em;
        color: white;
        opacity: 1;
        transition: opacity 0.5s ease-in-out; 
    }


    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
